








import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from "@vue/composition-api";
import { Message } from "@microsoft/microsoft-graph-types";
import MailItem from "./MailItem.vue";

export default defineComponent({
  components: { MailItem },
  props: {
    value: Array as PropType<Message[]>,
  },
  setup(props) {
    const { value } = toRefs(props);

    const model = computed({
      get: () => value?.value,
      set: (val) => console.log(val),
    });

    return {
      model,
    };
  },
});
