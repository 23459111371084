




















import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from "@vue/composition-api";
import { Message } from "@microsoft/microsoft-graph-types";

export default defineComponent({
  props: {
    mail: Object as PropType<Message>,
  },
  setup(props) {
    const { mail } = toRefs(props);

    const avatarLetter = computed(() =>
      mail?.value?.from?.emailAddress?.name?.substring(0, 1)
    );
    const subject = computed(() => mail?.value?.subject);
    const bodyPreview = computed(() => mail?.value?.bodyPreview);

    return {
      avatarLetter,
      subject,
      bodyPreview,
    };
  },
});
