










import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from "@vue/composition-api";
import { MailFolder } from "@microsoft/microsoft-graph-types";
import FolderItem from "./FolderItem.vue";

export default defineComponent({
  components: { FolderItem },
  props: {
    folders: Array as PropType<MailFolder[]>,
    value: String,
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);

    const model = computed({
      get: () => value?.value,
      set: (val) => emit("input", val),
    });

    return {
      model,
    };
  },
});
