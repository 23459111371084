































import FolderList from "@/components/mail/FolderList.vue";
import MailList from "@/components/mail/MailList.vue";
import { CollectionResponse } from "@/plugins/microsoft";
import { MailFolder, Message } from "@microsoft/microsoft-graph-types";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: { MailList, FolderList },
  layout: "authenticated",
  setup(): Record<string, unknown> {
    const instance = getCurrentInstance();
    const mails = ref<Message[]>([]);
    const defaultFolders = ref<MailFolder[]>([]);
    const mailFolders = ref<MailFolder[]>([]);
    const currentFolder = ref<string | undefined>(undefined);

    const LoadDefaultFolders = async () =>
      Promise.allSettled(
        [
          "inbox",
          "drafts",
          "outbox",
          "sentitems",
          "deleteditems",
        ].map((folder) => instance?.proxy?.$microsoft.getMailFolder(folder))
      )
        .then(
          (promises: PromiseSettledResult<Promise<MailFolder> | undefined>[]) =>
            promises
              .filter(
                (
                  promise: PromiseSettledResult<Promise<MailFolder> | undefined>
                ) => (promise.status = "fulfilled")
              )
              .map(
                (
                  promise: PromiseSettledResult<Promise<MailFolder> | undefined>
                ) => (promise as PromiseFulfilledResult<MailFolder>).value
              )
        )
        .then((folders: MailFolder[]) => {
          defaultFolders.value = folders;
          console.log("Default Folders", defaultFolders.value);
        });

    const loadMailFolders = () =>
      instance?.proxy?.$microsoft
        ?.getMailFolders()
        .then((res: CollectionResponse<MailFolder>) => {
          mailFolders.value = res.value
            .filter(
              (folder) =>
                defaultFolders.value.findIndex((f) => f.id === folder.id) === -1
            )
            .sort((a, b) => {
              if (a?.displayName === null || a?.displayName === undefined)
                return -1;
              else if (b?.displayName === null || b?.displayName === undefined)
                return -1;
              else if (a?.displayName > b?.displayName) return 1;
              else if (a?.displayName < b?.displayName) return -1;
              else return 0;
            });
            console.log("Other Folders", mailFolders.value);
        });

    onMounted(async () => {
      await LoadDefaultFolders();
      await loadMailFolders();
    });

    const loadMails = (folder: string) =>
      instance?.proxy?.$microsoft
        ?.getMailFromFolder(folder)
        .then((res: CollectionResponse<Message>) => {
          console.log("Loaded new mails");
          mails.value = res.value;
        });

    watch(currentFolder, (folder: string | undefined) => {
      if (folder) {
        loadMails(folder);
      }
    });

    return {
      mails,
      defaultFolders,
      mailFolders,
      currentFolder,
    };
  },
});
