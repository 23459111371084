













import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from "@vue/composition-api";
import { MailFolder } from "@microsoft/microsoft-graph-types";

export default defineComponent({
  props: {
    folder: Object as PropType<MailFolder>,
  },
  setup(props) {
    const { folder } = toRefs(props);

    const name = computed(() => folder?.value?.displayName);
    const unread = computed(() => folder?.value?.unreadItemCount ?? 0);

    return {
      name,
      unread,
    };
  },
});
